import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from 'react';

export const NavigationContext = createContext<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}>({
  open: false,
  setOpen: () => {},
});

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const value = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open, setOpen]
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
