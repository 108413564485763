import { Button, Dialog } from '@mui/material';
import { DialogActions, DialogContainer, DialogTitle } from '../Dialog';
import { useIsPhoneSize } from '../hooks/useResponsive';

export type BTReconnectDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function BTReconnectDialog({ open, onConfirm = () => {}, onCancel = () => {} }: BTReconnectDialogProps) {
  const isPhoneSize = useIsPhoneSize();
  return (
    <Dialog open={open} maxWidth="xs" fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogTitle title="The scale was disconnected" onCloseClick={onCancel} />
        <p>Would you like to reconnect?</p>
        <DialogActions>
          <Button variant="text" type="reset" onClick={onCancel}>
            Close
          </Button>
          <Button onClick={onConfirm}>Reconnect</Button>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
}
