import type { ButtonClasses, ButtonProps } from '@mui/material';
import { Theme, createTheme, lighten } from '@mui/material/styles';
import type { OverridesStyleRules } from '@mui/material/styles/overrides';
import { blue, brown, cyan, grape, green, grey, lime, orange, pink, red, teal, violet, yellow } from '@/common/theme/colors';
import './types';

const buttonProps: ButtonProps = {
  variant: 'contained',
  disableElevation: true,
  disableFocusRipple: true,
  disableRipple: true,
  disableTouchRipple: true,
};

const buttonStyleOverrides: Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton', Omit<Theme, 'components'>>> =
  {
    root: ({ theme, ownerState }) => ({
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      fontWeight: 700,
      textTransform: 'none',
      minWidth: theme.spacing(16.25),
      '&:hover': {
        backgroundColor: ownerState.variant === 'contained' ? theme.palette.primary.main : 'transparent',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 0,
        fontSize: '12px',

        '.MuiSvgIcon-root': {
          width: theme.spacing(2),
          height: theme.spacing(2),
        },
      },
    }),
  };

export const theme = createTheme({
  palette: {
    customGrey: {
      main: grey[700],
      contrastText: grey[700],
      lighter: grey[100],
    },
    customPurple: {
      main: violet[600],
      contrastText: violet[600],
      lighter: violet[50],
    },
    customGreen: {
      main: cyan[600],
      contrastText: cyan[600],
      lighter: cyan[50],
    },
    customYellow: {
      main: yellow[600],
      contrastText: green[600],
      lighter: yellow[100],
    },
    primary: {
      main: red[600],
      lighter: red[50],
    },
    secondary: {
      main: grey[600],
      lighter: red[50],
    },
    text: {
      primary: '#000000',
      secondary: grey[600],
    },
    success: {
      main: green[500],
      contrastText: green[600],
      lighter: green[100],
    },
    background: {
      navigation: grey[50],
    },
    grey,
    cyan,
    violet,
    green,
    red,
    brown,
    blue,
    orange,
    pink,
    teal,
    yellow,
    grape,
    lime,
  },
  components: {
    // @ts-ignore
    MuiLoadingButton: {
      defaultProps: buttonProps,
      styleOverrides: buttonStyleOverrides,
    },
    MuiAvatarGroup: {
      defaultProps: {
        componentsProps: {
          additionalAvatar: {
            sx: { width: 32, height: 32, fontSize: '12px', backgroundColor: cyan[500] },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: buttonProps,
      styleOverrides: buttonStyleOverrides,
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const color = ownerState.color === 'default' || !ownerState.color ? 'primary' : ownerState.color;
          return {
            backgroundColor: theme.palette[color].lighter || lighten(theme.palette[color].main, 0.85),
            color: theme.palette[color].main,
            height: 26,
            padding: 6,
            fontWeight: 600,
            lineHeight: 1,

            [theme.breakpoints.down('md')]: {
              fontSize: '10px',
              height: '18px',
              padding: 0,
              span: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
              },
            },

            '&.Mui-disabled': {
              opacity: 1,
              color: theme.palette.grey[500],
              backgroundColor: theme.palette.grey[100],
            },
          };
        },
        deleteIcon: {
          color: 'inherit',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: () => ({
          '.MuiDialog-paperFullScreen': {
            paddingTop: 'env(safe-area-inset-top)',
          },
        }),
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        hiddenLabel: true,
        margin: 'none',
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '12px',
          '.MuiSvgIcon-root': {
            fontSize: '20px',
            margin: theme.spacing(1),
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          marginLeft: 0,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '1rem',
          backgroundColor: '#fff',
          borderColor: theme.palette.grey[400],
          'input.MuiInputBase-input': {
            padding: '10.5px 14px 8.5px',
          },
          'textarea.MuiInputBase-input': {
            padding: '6px 0',
          },
          ['&:focus']: {
            borderColor: theme.palette.primary.main,
          },
          ['&.Mui-disabled']: {
            backgroundColor: theme.palette.grey[50],
          },
          'fieldset legend': {
            width: 0,
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 600,
          color: theme.palette.grey[600],
          position: 'static',
          transformOrigin: 'inherit',
          letterSpacing: 'inherit',
          fontSize: '14px',
          maxWidth: 'inherit',
          transform: 'none',
          pointerEvents: 'inherit',
          userSelect: 'inherit',
          display: 'flex',
          alignItems: 'center',
          overflow: 'inherit',
          height: '30px',
          lineHeight: 1,
          padding: 0,
          margin: 0,
          textOverflow: 'initial',
          cursor: 'pointer',
          '&.Mui-disabled': {
            cursor: 'default',
          },
          '.MuiFormLabel-asterisk': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[700],
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: () => ({
          padding: '4px 9px',
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },

      styleOverrides: {
        root: () => ({
          maxWidth: '100%',
          '.MuiInputBase-input': {
            paddingTop: '10.5px',
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          fontSize: '1rem',
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiMenuItem-root': {
            [theme.breakpoints.down('md')]: {
              padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
              minHeight: theme.spacing(2),
            },
          },
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          'thead th': {
            fontWeight: 600,
          },
          th: {
            padding: '16px 17px',
            borderColor: theme.palette.grey[300],
          },
          [theme.breakpoints.down('md')]: {
            'th, td': {
              lineHeight: 1.2,
              height: '48px',
              padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            },
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,

          '.MuiButtonBase-root': {
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
              fontSize: '12px',
              textTransform: 'none',
              whiteSpace: 'nowrap',
            },
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiInputBase-root': {
            lineHeight: 1,
          },
        }),
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'footNote' }, //  May need a better name
          style: {
            color: grey[700],
            fontSize: '12px',
          },
        },
      ],
    },
  },
});

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: '14px',
  fontWeightRegular: 400,
  fontWeightBold: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
};

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '20px',
  lineHeight: 1.6,
  fontWeight: 700,

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
};
theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '18px',
  lineHeight: 1.6,
  fontWeight: 600,

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
};
theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '16px',
  lineHeight: 1.75,
  fontWeight: 600,

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
};
