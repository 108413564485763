import { Level } from 'pino';
import { MonitorService } from '../types';
import { Registry } from './Registry';

export class AnalyticRegistry implements MonitorService {
  constructor(private registry: Registry) {}

  init = async () => {
    const requests = [];
    for (const key in this.registry.services) {
      requests.push(this.registry.services[key].init());
    }
    await Promise.allSettled(requests);
  };

  startRecording = async () => {
    const requests = [];
    for (const key in this.registry.services) {
      requests.push(this.registry.services[key].startRecording());
    }
    await Promise.allSettled(requests);
  };

  stopRecording = async () => {
    const requests = [];
    for (const key in this.registry.services) {
      requests.push(this.registry.services[key].stopRecording());
    }
    await Promise.allSettled(requests);
  };

  setUser = async (user: any) => {
    const requests = [];
    for (const key in this.registry.services) {
      requests.push(this.registry.services[key].setUser(user));
    }
    await Promise.allSettled(requests);
  };

  sendLog = async (level: Level, message: Record<string, any>) => {
    const requests = [];
    for (const key in this.registry.services) {
      requests.push(this.registry.services[key].sendLog(level, message));
    }
    await Promise.allSettled(requests);
  };

  setContext = (key: string, value: any) => {
    for (const name in this.registry.services) {
      this.registry.services[name].setContext(key, value);
    }
  };
}
