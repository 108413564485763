import UAParser from 'ua-parser-js';

export interface UAInfo {
  isRedbookApp: boolean;
  isIpadApp: boolean;
  isIphoneApp: boolean;
  OSVersion: string | undefined;
  OSName: string | undefined;
  appVersion: string | undefined;
  iOSVersion: string | undefined;
  ua: UAParser.IResult;
}

export const getUAInfo = (): UAInfo => {
  const userAgent = navigator.userAgent;
  // We use custom userAgent in the app
  // "(\(Device.current)) iOS/\(shortOSVersion) Redbook/\(shortVersionString)"
  const ua = UAParser(userAgent);
  return {
    isRedbookApp: userAgent.includes('Redbook'),
    isIpadApp: userAgent.includes('Redbook') && userAgent.includes('iPad'),
    isIphoneApp: userAgent.includes('Redbook') && userAgent.includes('iPhone'),
    OSVersion: ua.os.version,
    OSName: ua.os.name,
    appVersion: userAgent.split(' ').at(-1)?.split('/')?.[1],
    iOSVersion: userAgent.split(' ').at(-2)?.split('/')?.[1],
    ua,
  };
};
