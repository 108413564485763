import { logger as Logger } from '@/components/Analytics';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

const logger = Logger.child({ module: 'LoginRequiredProvider' });

export const LoginRequiredProvider = ({ children }: { children: ReactNode }) => {
  const { user, isLoading } = useUser();
  const router = useRouter();
  useEffect(() => {
    if (!isLoading && !user) {
      logger.debug('User is not logged in, redirecting to login page');
      router.replace('/api/auth/login?returnTo=/dashboard');
    }
  }, [isLoading, router, user]);

  // NOTE: user may not be available and will cause requests to respond with 401
  return (
    <>{!user ? <Box>Loading...</Box> : <Box style={user ? { height: '100%' } : { display: 'none' }}>{children}</Box>}</>
  );
};
