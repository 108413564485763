import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { atom, useAtom } from 'jotai';

export const desktopModeAtom = atom<boolean>(false);

export const useDesktopModeAtom = () => useAtom(desktopModeAtom);

export const useIsPhoneSize = () => {
  const [desktopMode] = useDesktopModeAtom();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  if (desktopMode) {
    return false;
  }
  return matches;
};
