import { UserPermissions } from '@/common/types/userPermissions';
import { useGetOrganizationsQuery } from '@/web-types';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useIsSuperAdmin } from '../Admin/useIsSuperAdmin';
import { userPermissionsAtom } from './Permissions';
import gql from 'graphql-tag';

gql`
  query GetOrganizations {
    getOrganizations {
      id
      name
      primaryUserName
    }
  }
`;

export const SetSuperAdminPermissions = () => {
  const [permissions, setPermissions] = useAtom(userPermissionsAtom);

  const isSuperAdmin = useIsSuperAdmin();
  const [{ data: orgData }] = useGetOrganizationsQuery({
    pause: !isSuperAdmin,
  });

  useEffect(() => {
    if (!isSuperAdmin || !orgData?.getOrganizations?.length) {
      return;
    }
    const memberships = orgData.getOrganizations.map((org) => ({
      orgId: org.id,
      orgName: org.name,
      role: 'OWNER',
      primaryUserName: org.primaryUserName,
    }));

    // The previous implementation fetched all memberships during auth. This caused a bug where the cookie would be not set
    // This is a hack to emulate the previous memberships behavior.
    setPermissions({ ...permissions, memberships } as UserPermissions);
  }, [orgData?.getOrganizations]);

  return <></>;
};
