import { VERSION } from '@/components/Providers/config';
import dayjs, { Dayjs } from 'dayjs';
import EventEmitter from 'events';
import * as semver from 'semver';
import { FETCH_INTERVAL, FETCH_THROTTLE_MINUTES } from './constants';
import { StatusResponse } from './types';
import { analytics } from '../Analytics/analytics';

class VersionChecker extends EventEmitter {
  constructor() {
    super();
    if (typeof window !== 'undefined') {
      // recheck when app is in background and then refocused
      window.addEventListener('focus', this.onWindowFocus);
      window.addEventListener('blur', this.onWindowBlur);
    }
  }

  currentVersion = VERSION; // NOTE: app build version
  latestVersion?: string;
  updateAvailable = false;
  releaseType?: string;
  private lastChecked?: Dayjs;
  private refreshIntervalId?: ReturnType<typeof setInterval>;

  private onWindowFocus = () => {
    this.checkVersion();
    this.refreshIntervalId = setInterval(this.checkVersion, FETCH_INTERVAL);
  };

  private onWindowBlur = () => {
    clearInterval(this.refreshIntervalId);
  };

  throttleCheckVersion = async () => {
    if (this.lastChecked && dayjs().diff(this.lastChecked, 'minute') < FETCH_THROTTLE_MINUTES) {
      return this.updateAvailable;
    }
    return this.checkVersion();
  };

  checkVersion = async () => {
    this.lastChecked = dayjs();

    const response = await fetch('/api/status');
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const { version } = (await response.json()) as StatusResponse;
    const [latestVersion, flag] = version.split('-');
    const currentVersion = this.currentVersion.split('-')[0];
    const releaseType = semver.diff(latestVersion, currentVersion);
    this.latestVersion = latestVersion;
    this.updateAvailable = semver.gt(latestVersion, currentVersion);

    if (releaseType) {
      this.releaseType = releaseType;
    }
    if (!flag && this.updateAvailable) {
      this.emit('updateAvailable', {
        updateAvailable: this.updateAvailable,
        releaseType: this.releaseType,
        latestVersion,
      });
    }

    analytics.setContext('server-version', latestVersion);

    return this.updateAvailable;
  };
}

export const versionChecker = new VersionChecker();
