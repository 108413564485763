import { EditContext } from '@/components/EditContext/EditContext';
import { EditorEventHandler, SetContinueEvent, SetPreventEvent } from '@/components/EditContext/types';
import { useCallback, useMemo, useRef, useState } from 'react';

export const EditProvider = ({ children }: { children: JSX.Element }) => {
  const editingRef = useRef<boolean>(false);
  const handlePreventEvent = useRef<EditorEventHandler | null>(null);
  const handleContinueEvent = useRef<EditorEventHandler | null>(null);
  const [editing, setEditingState] = useState<boolean>(false);
  const setEditing = useCallback((value: boolean) => {
    editingRef.current = value;
    setEditingState(value);
  }, []);
  const setPreventEvent: SetPreventEvent = useCallback((handler) => {
    handlePreventEvent.current = handler;
  }, []);
  const setContinueEvent: SetContinueEvent = useCallback((handler) => {
    handleContinueEvent.current = handler;
  }, []);
  const continueEvent = useCallback(() => {
    handleContinueEvent.current?.();

    // Reset context
    editingRef.current = false;
    handleContinueEvent.current = null;
    handlePreventEvent.current = null;
  }, []);
  // this does not require state change. important to not block routing
  const checkEditingAndPreventEvent = useCallback(() => {
    if (editingRef.current) {
      handlePreventEvent.current?.();
    }
    return editingRef.current;
  }, []);

  const contextValue = useMemo(
    () => ({
      _editingRef: editingRef,
      _handlePreventEvent: handlePreventEvent,
      editing,
      checkEditingAndPreventEvent,
      continueEvent,
      setEditing,
      setPreventEvent,
      setContinueEvent,
    }),
    [checkEditingAndPreventEvent, continueEvent, editing, setContinueEvent, setEditing, setPreventEvent]
  );

  return <EditContext.Provider value={contextValue}>{children}</EditContext.Provider>;
};
