import { SessionUser, UserPermissions } from '@/common/types/userPermissions';
import { setPermissionCookies } from '@/components/helpers/cookies';
import { useUser } from '@auth0/nextjs-auth0/client';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

export const userPermissionsAtom = atom<UserPermissions | null>(null);

export const useUserPermissionsAtom = () => useAtom(userPermissionsAtom);

export const Permissions = () => {
  const { user: _user, isLoading } = useUser();
  const [, setPermissions] = useUserPermissionsAtom();

  useEffect(() => {
    if (isLoading || !_user || !_user.permissions) {
      return;
    }
    const user = _user as unknown as SessionUser;
    setPermissionCookies(user.permissions, user.permissionsHash);
    setPermissions(user.permissions);
  }, [isLoading, setPermissions, _user]);

  return <></>;
};

export const NoAuthPermissions = () => {
  const [, setPermissions] = useUserPermissionsAtom();

  useEffect(() => {
    const fetchPerms = async () => {
      const res = await fetch('/api/auth/no-auth-permissions');
      const permissions = await res.json();
      setPermissions(permissions);
    };
    fetchPerms();
  }, [setPermissions]);

  return <></>;
};
