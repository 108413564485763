import { useCallback, useMemo, useState } from 'react';
import { UseDialogReturn } from './types';

export const useDialog = (): UseDialogReturn => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  return useMemo(
    () => ({
      open,
      setOpen,
      handleClose,
      handleOpen,
    }),
    [open, handleClose, handleOpen]
  );
};
