import { logger as Logger } from '@/components/Analytics';
import { PureComponent } from 'react';
import { ErrorBoundaryProps } from './types';

const logger = Logger.child({ module: 'ErrorBoundary' });

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    logger.fatal(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
