export const grey = {
  50: '#F8F9FA',
  100: '#F1F3F5',
  200: '#E9ECEF',
  300: '#DEE2E6',
  400: '#CED4DA',
  500: '#ADB5BD',
  600: '#868E96',
  700: '#495057',
  800: '#343A40',
  900: '#212529',
};
export const red = {
  50: '#FFF6F6',
  100: '#FFE8E8',
  200: '#FECACA',
  300: '#FCA5A5',
  400: '#F87171',
  500: '#EF4444',
  600: '#DC2626',
  700: '#B91C1C',
  800: '#991B1B',
  900: '#7F1D1D',
};
export const green = {
  50: '#F0FDF4',
  100: '#DCFCE7',
  200: '#BBF7D0',
  300: '#86EFAC',
  400: '#4ADE80',
  500: '#22C55E',
  600: '#16A34A',
  700: '#15803D',
  800: '#166534',
  900: '#14532D',
};
export const yellow = {
  50: '#FFFBEB',
  100: '#FEF3C7',
  200: '#FDE68A',
  300: '#FBD34D',
  400: '#FBBF24',
  500: '#F59E0B',
  600: '#D97706',
  700: '#B45309',
  800: '#92400E',
  900: '#78350F',
};
export const orange = {
  50: '#FDF4E7',
  100: '#FBE9CF',
  200: '#FAD8AE',
  300: '#F6C283',
  400: '#F4AC5F',
  500: '#F19645',
  600: '#EE8437',
  700: '#E76F2E',
  800: '#D8612B',
  900: '#CA5228',
};
export const brown = {
  50: '#FEF9DE',
  100: '#FEF2C5',
  200: '#FDECA3',
  300: '#FBE079',
  400: '#F9D55B',
  500: '#F4C548',
  600: '#F0B23F',
  700: '#B68015',
  800: '#9E6B04',
  900: '#855A04',
};
export const blue = {
  50: '#EAF5FE',
  100: '#D4EAFD',
  200: '#AFD8FB',
  300: '#84BFF7',
  400: '#62AAF1',
  500: '#4D9AE9',
  600: '#408BDF',
  700: '#387ED0',
  800: '#3171BC',
  900: '#2D64A6',
};
export const violet = {
  50: '#F2F0FE',
  100: '#E2DBFC',
  200: '#CCC1FA',
  300: '#AC9AF5',
  400: '#9079F2',
  500: '#7C64EE',
  600: '#7057E9',
  700: '#674FE0',
  800: '#5F48D1',
  900: '#5842BD',
};
export const grape = {
  50: '#F7F0FB',
  100: '#EFDAF8',
  200: '#E6C1F6',
  300: '#DA9EF1',
  400: '#CC7EEB',
  500: '#BD66E1',
  600: '#AF56D4',
  700: '#A04AC2',
  800: '#9040AF',
  900: '#7C3797',
};
export const lime = {
  50: '#F4FCE3',
  100: '#E9FAC8',
  200: '#D8F5A2',
  300: '#C0EB75',
  400: '#A9E34B',
  500: '#94D82D',
  600: '#82C91E',
  700: '#74B816',
  800: '#66A80F',
  900: '#5C940D',
};
export const teal = {
  50: '#E6FCF5',
  100: '#C3FAE8',
  200: '#96F2D7',
  300: '#63E6BE',
  400: '#38D9A9',
  500: '#20C997',
  600: '#12B886',
  700: '#0CA678',
  800: '#099268',
  900: '#087F5B',
};
export const cyan = {
  50: '#E2FDFF',
  100: '#C1FBFF',
  200: '#9FF6FC',
  300: '#6FEEF6',
  400: '#49CED7',
  500: '#3ABDC6',
  600: '#2DA6AE',
  700: '#1B8E96',
  800: '#107D84',
  900: '#09646A',
};
export const pink = {
  50: '#FCF1F6',
  100: '#FADFEA',
  200: '#F3C4D6',
  300: '#EDA6C0',
  400: '#E989AB',
  500: '#E06F94',
  600: '#D65680',
  700: '#C6436C',
  800: '#B3365C',
  900: '#992D4D',
};
