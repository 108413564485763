import { LOG_LEVEL } from '@/components/Providers/config';
import pino from 'pino';
import { analytics } from './analytics';
import { LoggerInterface } from './types';

// Not available in Safari
// https://caniuse.com/?search=downlink%20
const getConnectionInfo = () => {
  if ('connection' in window.navigator) {
    const connection = window.navigator.connection as any;
    // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
    return {
      connectionDownloadMb: connection.downloadlink,
      connectionEffectiveType: connection.effectiveType,
      connectionType: connection.type,
    };
  }
  return {};
};

const _logger = pino({
  level: LOG_LEVEL,
  browser: {
    transmit: {
      send: (level, logEvent) => {
        const logMessage = logEvent.messages.concat(logEvent.bindings).reduce(
          (payload, message) => {
            if (typeof message === 'string') {
              return { msg: message };
            } else if (message instanceof Error || message.type === 'Error') {
              return { ...payload, error: message, msg: payload.msg ?? message.msg };
            }
            return { ...payload, ...message, msg: payload.msg ?? message.msg };
          },
          { date: logEvent.ts, ...getConnectionInfo() }
        );
        analytics.sendLog(level, logMessage);
      },
    },
  },
});

export const logger = _logger as LoggerInterface;
