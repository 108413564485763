import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const DialogContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}));

export const DialogHeader = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1),
}));

export const DialogContent = styled(Box)(() => ({
  textAlign: 'center',
}));

export const DialogActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
}));

const DialogTitleContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 20,
  background: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  pb: 3,
}));

interface DialogTitleProps {
  title?: string;
  onCloseClick?: (a?: any, b?: any) => any;
  containerProps?: BoxProps;
}

export const DialogTitle = ({ title = '', onCloseClick, containerProps = {} }: DialogTitleProps) => {
  return (
    <DialogTitleContainer {...containerProps}>
      <Typography variant="h1">{title}</Typography>

      {onCloseClick ? (
        <IconButton onClick={onCloseClick}>
          <CloseIcon color="inherit" />
        </IconButton>
      ) : (
        <></>
      )}
    </DialogTitleContainer>
  );
};

export const SuccessDialogContent = styled(DialogContent)(({ theme }) => ({
  color: theme.palette.green[600],
}));
