import { EditContextValue } from '@/components/EditContext/types';
import { createContext } from 'react';

export const EditContext = createContext<EditContextValue>({
  editing: false,
  checkEditingAndPreventEvent: () => false,
  setEditing: () => {},
  setPreventEvent: () => {},
  setContinueEvent: () => {},
  continueEvent: () => {},
});
