import { SnackbarProvider as NotistackProvider } from 'notistack';
import { FC, PropsWithChildren } from 'react';

export const SnackbarProvider: FC<PropsWithChildren> = (props) => {
  return (
    <NotistackProvider maxSnack={3} dense preventDuplicate>
      {props.children}
    </NotistackProvider>
  );
};
