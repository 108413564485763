import { EditContext } from '@/components/EditContext/EditContext';
import { Router, useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { UseEditContextOnFormStateProps } from './types';
import { ERROR_MESSAGES } from '@/common/messages';

export const useEditContext = () => {
  return useContext(EditContext);
};

export const useEditContextPreventRouteChange = () => {
  const { _handlePreventEvent, checkEditingAndPreventEvent, setContinueEvent } = useEditContext();
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = (url: string) => {
      if (checkEditingAndPreventEvent()) {
        _handlePreventEvent?.current?.();
        setContinueEvent(() => router.push(url));
        throw ERROR_MESSAGES.EDIT_IN_PROGRESS; // NOTE: This is required to prevent the route change. May affect logging.
      }
    };
    Router.events.on('routeChangeStart', onRouteChangeStart);
    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [_handlePreventEvent, checkEditingAndPreventEvent, router, setContinueEvent]);
};

export const useEditContextOnFormState = ({ isDirty, isSubmitted }: UseEditContextOnFormStateProps) => {
  const { setEditing } = useEditContext();
  useEffect(() => {
    if (isDirty && !isSubmitted) {
      setEditing(true);
    }
  }, [isDirty, isSubmitted, setEditing]);
};
