import { UserPermissions } from '@/common/types/userPermissions';
import { useOrgId } from '@/components/Providers/auth/OrgIdProvider';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useEffect } from 'react';
import { analytics } from './analytics';

export const UserAnalytics = () => {
  const { user } = useUser();
  const { orgId } = useOrgId();

  useEffect(() => {
    const perms = user?.permissions as UserPermissions | undefined;
    const orgName = orgId ? perms?.memberships?.find((m) => m.orgId == orgId)?.orgName ?? '' : '';
    analytics.setUser({
      user,
      orgId,
      orgName,
    });
  }, [user, orgId]);

  useEffect(() => {
    if (user?.email && orgId) {
      analytics.startRecording();
    } else {
      analytics.stopRecording();
    }
  }, [user?.email, orgId]);

  return null;
};
