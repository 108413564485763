import { Button, Dialog } from '@mui/material';
import { DialogContainer, DialogTitle } from '../Dialog';
import { useIsPhoneSize } from '../hooks/useResponsive';

export type BTGestureNeededDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function BTGestureNeededDialog({ open, onClose = () => {} }: BTGestureNeededDialogProps) {
  const isPhoneSize = useIsPhoneSize();
  return (
    <Dialog open={open} maxWidth="xs" fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogTitle
          title="Enable Bluetooth"
          onCloseClick={onClose}
          containerProps={{
            sx: {
              pb: 3,
            },
          }}
        />
        <Button onClick={onClose}>Click to enable bluetooth</Button>
      </DialogContainer>
    </Dialog>
  );
}
