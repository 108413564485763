import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { localdate, LocalDateRange } from '../models/types';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

export const Timezones = {
  MT: 'America/Denver',
  CT: 'America/Chicago',
};

export const localDateFormat = 'YYYY-MM-DD';

export const addDays = (date: localdate, numDays: number): localdate =>
  addDaysDayjs(dayjs(date), numDays).format(localDateFormat);

export const getDateRange = ({
  periodStart,
  periodEnd,
  isAscending = true,
}: {
  periodStart: localdate;
  periodEnd: localdate;
  isAscending?: Boolean;
}): string[] => {
  const dates: string[] = [];
  const dayjsPeriodEnd = dayjs(periodEnd);
  let dayjsCurrent = dayjs(periodStart);

  if (dayjsPeriodEnd.isBefore(dayjsCurrent)) {
    throw new Error(`periodEnd ${periodEnd} is before periodStart ${periodStart}`);
  }

  while (true) {
    dates.push(dayjsCurrent.format(localDateFormat));
    if (dayjsCurrent.isSame(dayjsPeriodEnd)) {
      break;
    }
    dayjsCurrent = dayjsCurrent.add(1, 'day');
  }

  return isAscending ? dates : dates.reverse();
};

/**
 * Sort date range by periodEnd, then periodStart. Example:
 *
 *   - August
 *   - August 16 - 31
 *   - August 1 - 15
 *   - July
 *   - July 16 - 31
 *   - July 1 - 15
 */
export const dateRangeSorter = (a: LocalDateRange, b: LocalDateRange) => {
  if (a.periodEnd == b.periodEnd) {
    if (a.periodStart == b.periodStart) {
      return 0;
    }
    return a.periodStart > b.periodStart ? 1 : -1;
  }
  if (b.periodEnd == a.periodEnd) {
    return 0;
  }
  return b.periodEnd > a.periodEnd ? 1 : -1;
};

// This function is alternative variant of dayjs.isBetween method, but it supports start and end dates as optional parameters
export const isBetweenDatesInclusive = (date: dayjs.Dayjs, start?: dayjs.Dayjs, end?: dayjs.Dayjs): boolean => {
  let isBetween = true;
  if (start) isBetween = isBetween && date.isSameOrAfter(start);
  if (end) isBetween = isBetween && date.isSameOrBefore(end);
  return isBetween;
};

/**
 * Equivalent to dayjs.add(days, 'day'), but fixes the Daylight Saving issue.
 * /!\ ONLY WORKS IF `dateWithTZ` IS AT MIDNIGHT IN ITS TIMEZONE.
 * See: https://github.com/iamkun/dayjs/issues/1271#issuecomment-772542205
 */
export const addDaysDayjs = (dateWithTZ: dayjs.Dayjs, days: number) => {
  const clone = dayjs(dateWithTZ) as any;
  const tz = clone.$x.$timezone;
  return clone.date(clone.date() + days).tz(tz, true);
};
