// Hardcoded identifiers (they're the same for all Scale-Tec Point scales)
export const namePrefix = 'Point-';
export const serviceUuid = '0c2ad08c-5065-49ed-a6e3-5a8a05cee69b';
export const characteristicUuids = {
  weight: 'a4d42b3e-d45b-42fe-9e65-05fd789dab75',
  zero: '0e358a1c-758a-4926-94a0-d861997a1585',
};

export const getPointWeight = (dv: DataView, logDebug: (msg: string) => void): number => {
  const buf = new Uint8Array(dv.buffer);
  //logDebug(`Buffer: ${buf[0]} ${buf[1]} ${buf[2]} ${buf[3]}`);
  const weight = new DataView(dv.buffer.slice(0, 2)).getUint16(0, true);
  switch (buf[3]) {
    case 80: // negative, float
      return -weight / 10;
    case 64: // negative, integer
      return -weight;
    case 16: // positive, float
      return weight / 10;
    case 0: // positive, integer
    default:
      return weight;
  }
};

export const zeroOut = (ch: BluetoothRemoteGATTCharacteristic) => ch.writeValue(new Uint8Array([1]));
