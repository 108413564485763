import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { userPermissionsAtom } from '@/components/hooks/Permissions';
import { removeOrgIdCookie, setOrgIdCookie } from '@/components/helpers/cookies';
import { useCookies } from 'react-cookie';
import { orgIdCookieName } from '@/common/config';
import { useIsSuperAdmin } from '@/components/Admin/useIsSuperAdmin';
import { sortBy } from 'lodash';
import { emDash } from '@/components/Typography/emDash';


const OrgIdContext = createContext<{
  orgId: number | null;
  setOrgId: (orgId: number | null) => void;
}>({
  orgId: null,
  setOrgId: () => {},
});

export const OrgIdProvider = ({ children }: { children: ReactNode }) => {
  const [cookies] = useCookies([orgIdCookieName]);
  const [orgId, setOrgId] = useState<number | null>(null);

  const setOrganizationId = useCallback((orgId: number | null) => {
    setOrgId(orgId);
    if (orgId == null) {
      removeOrgIdCookie();
    } else {
      setOrgIdCookie(orgId);
    }
  }, []);

  useEffect(() => {
    const cookie = cookies[orgIdCookieName];
    if (cookie) {
      setOrgId(parseInt(cookie));
    }
  }, [cookies]);

  const value = useMemo(
    () => ({
      orgId,
      setOrgId: setOrganizationId,
    }),
    [orgId, setOrganizationId]
  );

  return <OrgIdContext.Provider value={value}>{orgId == null ? <OrgPicker /> : children}</OrgIdContext.Provider>;
};

const OrgPicker = () => {
  const [permissions] = useAtom(userPermissionsAtom);
  const { setOrgId: setOrganization } = useOrgId();
  const isSuperAdmin = useIsSuperAdmin();

  const onOrgClick = useCallback(
    (orgId: number) => () => {
      setOrganization(orgId);
    },
    [setOrganization]
  );

  useEffect(() => {
    if (!isSuperAdmin && permissions && permissions.memberships.length === 1) {
      const orgId = permissions.memberships[0].orgId;
      setOrganization(orgId);
    }
  }, [permissions, setOrganization]);

  if (permissions == null) {
    return <div>Loading...</div>;
  }

  if (!isSuperAdmin && permissions.memberships.length === 1) {
    const orgId = permissions.memberships[0].orgId;
    return <div>Choosing org {orgId}...</div>;
  }

  return (
    <Box sx={{ maxWidth: '300px', margin: '2em auto' }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Choose a feedyard
      </Typography>
      <Stack sx={{ alignItems: 'baseline' }} gap={1}>
        {sortBy(permissions.memberships, (m) => m.orgName).map((m) => (
          <Box key={m.orgId} sx={{ width: '100%' }}>
            <Button
              variant="outlined"
              onClick={onOrgClick(m.orgId)}
              sx={{ width: '100%', justifyContent: 'flex-start' }}
            >
              {m.orgName} {isSuperAdmin ? ` ${emDash} #${m.orgId}` : ''}
            </Button>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export const useOrgId = () => useContext(OrgIdContext);
