import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useCallback } from 'react';
import { useIsSuperAdmin } from '../Admin/useIsSuperAdmin';
import { useDevToolsContext } from '../DevTools/DevToolsProvider';
import { BTConnectionState } from './BTConnectionState';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  position: 'fixed',
  right: theme.spacing(2),
  bottom: `calc(${theme.spacing(2)} + env(safe-area-inset-bottom))`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  minHeight: theme.spacing(7),
  background: 'rgba(255 ,255 ,255 ,0.8)',
  width: expanded ? 'auto' : theme.spacing(7),
  zIndex: 3000,
  [theme.breakpoints.down('md')]: {
    bottom: `calc(${theme.spacing(9)} + env(safe-area-inset-bottom))`,
  },
}));

export const BTDevTool = (): JSX.Element | null => {
  const isSuperAdmin = useIsSuperAdmin();

  const {
    open: expanded,
    setOpen: setExpanded,
    setWeight,
    weight,
    connectionState,
    setConnectionState,
  } = useDevToolsContext();

  const addWeight = useCallback(
    (delta: number) => {
      setWeight((weight) => weight + delta);
    },
    [setWeight]
  );

  const onMinusButtonClick = useCallback(() => addWeight(-25), [addWeight]);
  const onPlusButtonClick = useCallback(() => addWeight(+25), [addWeight]);

  const onToggleChange = useCallback(
    (event: any, newValue: any) => {
      setConnectionState(newValue);
    },
    [setConnectionState]
  );

  const onToggleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  if (!isSuperAdmin) {
    return null;
  }
  return (
    <StyledCard expanded={expanded} data-testid="bt-dev-tool">
      <Box sx={{ position: 'absolute', right: '8px' }}>
        <IconButton data-testid="bt-dev-expand-button" onClick={onToggleExpand}>
          {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </Box>
      {expanded && (
        <Stack sx={{ paddingTop: '34px' }} spacing={1}>
          <Stack direction="row">
            <IconButton data-testid="bt-dev-tool-minus" onClick={onMinusButtonClick}>
              <RemoveIcon />
            </IconButton>
            <Box sx={{ fontSize: '14px', margin: '1em' }}>
              <span>Weight:&nbsp;</span>
              <span data-testid="bt-dev-tool-weight">{weight}</span>
            </Box>
            <IconButton data-testid="bt-dev-tool-plus" onClick={onPlusButtonClick}>
              <AddIcon />
            </IconButton>
          </Stack>
          <ToggleButtonGroup color="primary" value={connectionState} exclusive onChange={onToggleChange}>
            <ToggleButton
              sx={{ fontSize: '12px', padding: '3px 8px' }}
              value={BTConnectionState.DISCONNECTED}
              data-testid="bt-dev-tool-button-set-disconnected"
            >
              DISCONNECTED
            </ToggleButton>
            <ToggleButton
              sx={{ fontSize: '12px', padding: '3px 8px' }}
              value={BTConnectionState.CONNECTED}
              data-testid="bt-dev-tool-button-set-connected"
            >
              CONNECTED
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}
    </StyledCard>
  );
};
