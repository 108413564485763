import { VERSION, DATADOG_ENABLED, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN } from '@/components/Providers/config';
import { StatusType, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Level } from 'pino';
import { MonitorService } from '@/components/Analytics/types';
import { MESSAGE_BLACKLIST } from '@/components/Analytics/constants';
import { getUAInfo } from '@/components/helpers/ua';
import { ENV } from '@/common/env';

const config = {
  applicationId: DATADOG_APPLICATION_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'us5.datadoghq.com',
  service: 'webapp',
  env: ENV,
  version: VERSION || ENV,
};

export class DataDogMonitorService implements MonitorService {
  private get enabled() {
    return config.applicationId?.length && config.clientToken?.length && DATADOG_ENABLED;
  }

  private initialized = false;

  init = () => {
    if (!this.enabled) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve) => {
      if (!this.initialized) {
        datadogLogs.init({ ...config, forwardConsoleLogs: 'all' });
        datadogRum.init({
          ...config,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackResources: true,
          trackLongTasks: true,
          trackUserInteractions: true,
          actionNameAttribute: 'data-testid',
          defaultPrivacyLevel: 'allow',
          beforeSend: (event, context) => {
            // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#discard-a-rum-event
            // @ts-ignore
            if (context?.error && MESSAGE_BLACKLIST.some((str) => context.error.includes(str))) {
              return false;
            }
            return true;
          },
        });
        const uaInfo = getUAInfo();
        this.setContext('platform', getPlatform(uaInfo));
        this.setContext('ios-app-version', uaInfo.appVersion);
        this.setContext('ios-version', uaInfo.iOSVersion);
      }

      datadogRum.onReady(() => {
        this.initialized = true;
        resolve();
      });
    });
  };

  startRecording = async () => {
    if (!this.enabled) {
      return Promise.resolve();
    }

    await this.init();

    datadogRum.startSessionReplayRecording();
  };

  stopRecording = async () => {
    if (!this.enabled) {
      return Promise.resolve();
    }

    await this.init();

    datadogRum.stopSessionReplayRecording();
  };

  setUser = async (user: any) => {
    if (!this.enabled) {
      return Promise.resolve();
    }

    await this.init();

    if (user) {
      const payload: Record<string, unknown> = {};
      for (const key in user) {
        if (user[key]) {
          payload[key] = user[key];
        }
      }
      datadogLogs.setUser(payload);
      datadogRum.setUser(payload);
    } else {
      datadogLogs.clearUser();
      datadogRum.clearUser();
    }
  };

  sendLog = async (level: Level, { msg, error, ...messageContext }: Record<string, any>) => {
    if (!this.enabled) {
      return Promise.resolve();
    }

    await this.init();

    const logLevel = level in StatusType ? (level as StatusType) : 'error';
    datadogLogs.logger[logLevel](msg, messageContext, error);
  };

  setContext = (key: string, value: any) => {
    if (!this.enabled) {
      return;
    }
    datadogRum.setGlobalContextProperty(key, value);
    datadogLogs.setGlobalContextProperty(key, value);
  };
}

export const dataDogMonitorService = new DataDogMonitorService();

const getPlatform = (uaInfo: any) => {
  if (uaInfo.isIpadApp) {
    return `redbook-app-ipad`;
  }
  if (uaInfo.isIphoneApp) {
    return `redbook-app-iphone`;
  }
  // ua.os and ua.device are not reliable because they give 'Mac OS' for iOS devices
  return uaInfo.ua.browser.name;
};
