import { DialogActions, DialogContainer, DialogContent, DialogHeader } from '@/components/Dialog';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useIsPhoneSize } from '../hooks/useResponsive';

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  fontSize: 40,
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.main,
}));

export const ErrorDialog = () => {
  const isPhoneSize = useIsPhoneSize();
  const onRefreshClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isPhoneSize}>
      <DialogContainer data-testid="error-dialog-container">
        <DialogHeader>
          <StyledWarningIcon />
          <Typography variant="h1">Something Went Wrong</Typography>
        </DialogHeader>
        <DialogContent>
          <Typography>An error has occurred. Please reload the app.</Typography>
        </DialogContent>
        <DialogActions>
          <Button data-testid="error-dialog-container-refresh-button" onClick={onRefreshClick}>
            Reload
          </Button>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
};
