import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';
import { useLaunchDarklyIdentify } from './useLaunchDarklyIdentify';
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from '@/components/Providers/config';

const NonProvider = ({ children }: { children: ReactNode }) => <>{children}</>;

const LDProvider = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
  // https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html
  options: {
    bootstrap: 'localStorage',
  },
  // @ts-ignore
})(({ children }) => {
  useLaunchDarklyIdentify();
  return children;
}) as React.FC<{ children: ReactNode }>;

export const LaunchDarklyProvider = LAUNCH_DARKLY_CLIENT_SIDE_ID ? LDProvider : NonProvider;
