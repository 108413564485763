import { removeOrgIdCookie, removePermissionCookies } from '@/components/helpers/cookies';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';

const LogoutContext = createContext<{
  logout: () => void;
}>({
  logout: () => {},
});

export const LogoutProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const logout = useCallback(() => {
    removeOrgIdCookie();
    removePermissionCookies();
    router.replace('/api/auth/logout');
  }, [router]);

  const value = useMemo(
    () => ({
      logout,
    }),
    [logout]
  );

  return <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>;
};

export const useLogout = () => useContext(LogoutContext);
