import { BLUETOOTH_ENABLED } from '@/components/Providers/config';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BTConnectionState, ScaleState, useBTScale } from '../Bluetooth';

export const DevToolsContext = createContext<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  logOpen: boolean;
  setLogOpen: Dispatch<SetStateAction<boolean>>;
  weight: number;
  setWeight: Dispatch<SetStateAction<number>>;
  connectionState: BTConnectionState;
  setConnectionState: Dispatch<SetStateAction<ScaleState>>;
  zeroOut: () => void;
}>({
  open: false,
  setOpen: () => {},
  logOpen: false,
  setLogOpen: () => {},
  weight: 0,
  setWeight: () => {},
  connectionState: BTConnectionState.DISCONNECTED,
  setConnectionState: () => {},
  zeroOut: () => {},
});

export const DevToolsProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(!BLUETOOTH_ENABLED);
  const [logOpen, setLogOpen] = useState<boolean>(BLUETOOTH_ENABLED);
  const [weight, setWeight] = useState(0);
  const [connectionState, setConnectionState] = useState<ScaleState>(BTConnectionState.DISCONNECTED);
  const { zeroOutScale, setWeight: setScaleWeight } = useBTScale();

  const zeroOut = useCallback(async () => {
    setWeight(0);
    zeroOutScale();
  }, [zeroOutScale, setWeight]);

  useEffect(() => {
    setScaleWeight(connectionState == BTConnectionState.CONNECTED ? weight : null);
  }, [connectionState, setScaleWeight, weight]);

  const value = useMemo(
    () => ({
      open,
      setOpen,
      logOpen,
      setLogOpen,
      weight,
      setWeight,
      connectionState,
      setConnectionState,
      zeroOut,
    }),
    [open, setOpen, logOpen, setLogOpen, weight, setWeight, connectionState, setConnectionState, zeroOut]
  );

  return <DevToolsContext.Provider value={value}>{children}</DevToolsContext.Provider>;
};

export const useDevToolsContext = () => useContext(DevToolsContext);
