import { logger as Logger } from './logger';

const logger = Logger.child({ module: 'WindowError' });

/*
NOTE:
in development mode, logs will appear here and in React ErrorBoundary
in production mode, React ErrorBoundary will stay caught
*/
if (typeof window !== 'undefined') {
  window.addEventListener('error', ({ error }: ErrorEvent) => logger.error(error));
}
