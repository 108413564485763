import '@/components/helpers/date';
import { FeedingMethod, InvoiceTemplate, useGetOrgSettingsQuery } from '@/web-types';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

gql`
  query GetOrgSettings {
    getCurrentOrganization {
      timezone
      name
      roundupCalledNumbers
      defaultInvoiceTemplate
      feedingMethod
      batchFeedingMaxLoadLbs
      settingCallFeedDmiPerHeadIncrement
      settingCallFeedAfPerHeadIncrement
      settingCallFeedAfIncrement
    }
  }
`;

export interface OrganizationSettings {
  tz: string | null;
  name: string;
  roundupCalledNumbers: boolean;
  defaultInvoiceTemplate: InvoiceTemplate;
  feedingMethod: FeedingMethod;
  batchFeedingMaxLoadLbs: number;
  fetching: boolean;
  refetch: () => void;
  settingCallFeedDmiPerHeadIncrement: number;
  settingCallFeedAfPerHeadIncrement: number;
  settingCallFeedAfIncrement: number;
}

export const orgSettingsAtom = atom<OrganizationSettings>({
  tz: null,
  name: '',
  roundupCalledNumbers: false,
  defaultInvoiceTemplate: InvoiceTemplate.V2,
  feedingMethod: FeedingMethod.Targeted,
  batchFeedingMaxLoadLbs: 0,
  fetching: true,
  settingCallFeedDmiPerHeadIncrement: 0.1,
  settingCallFeedAfPerHeadIncrement: 0.25,
  settingCallFeedAfIncrement: 100,
  refetch: () => {},
});

export const OrgSettings = () => {
  const [{ fetching, data }, refetch] = useGetOrgSettingsQuery();
  const [, setSettings] = useAtom(orgSettingsAtom);

  const org = data?.getCurrentOrganization;

  useEffect(() => {
    if (fetching) return;

    const tz = org?.timezone ?? null;
    if (tz) dayjs.tz.setDefault(tz);

    setSettings({
      tz,
      name: org?.name ?? '',
      roundupCalledNumbers: org?.roundupCalledNumbers ?? false,
      defaultInvoiceTemplate: org?.defaultInvoiceTemplate ?? InvoiceTemplate.V2,
      feedingMethod: org?.feedingMethod ?? FeedingMethod.Targeted,
      batchFeedingMaxLoadLbs: org?.batchFeedingMaxLoadLbs ?? 0,
      settingCallFeedDmiPerHeadIncrement: org?.settingCallFeedDmiPerHeadIncrement ?? 0,
      settingCallFeedAfPerHeadIncrement: org?.settingCallFeedAfPerHeadIncrement ?? 0,
      settingCallFeedAfIncrement: org?.settingCallFeedAfIncrement ?? 0,
      fetching: false,
      refetch,
    });
  }, [
    fetching,
    org?.name,
    org?.batchFeedingMaxLoadLbs,
    org?.defaultInvoiceTemplate,
    org?.feedingMethod,
    org?.roundupCalledNumbers,
    org?.settingCallFeedAfIncrement,
    org?.settingCallFeedAfPerHeadIncrement,
    org?.settingCallFeedDmiPerHeadIncrement,
    org?.timezone,
    refetch,
    setSettings,
  ]);

  return <></>;
};

export const useOrgSettings = () => useAtom(orgSettingsAtom);
