import { useContext, useEffect, useRef } from 'react';
import { BTWeightContext } from './BTWeightProvider';

export const useBTScale = () => useContext(BTWeightContext);

export const useOnWeightChange = (onWeightChange: (weightDiff: number) => void) => {
  const { isConnected, weight, scaleLocked } = useBTScale();
  const prevWeight = useRef<number | null>(null);
  const disconnectedWeight = useRef<number | null>(null);
  const prevIsConnected = useRef<boolean>(isConnected);

  useEffect(() => {
    let diff = 0;
    if (weight !== null && isConnected) {
      if (disconnectedWeight.current !== null && !prevIsConnected.current) {
        diff = weight - disconnectedWeight.current;
      } else if (prevWeight.current !== null) {
        diff = weight - prevWeight.current;
      }
    }
    if (isConnected) {
      disconnectedWeight.current = null;
    } else if (prevWeight.current !== null) {
      disconnectedWeight.current = prevWeight.current;
    }

    prevWeight.current = weight;
    prevIsConnected.current = isConnected;

    if (!scaleLocked) {
      onWeightChange(diff);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, scaleLocked]);
};
